<template>
  <div>

    <div class="d-flex justify-content-between align-items-baseline">
      <h2>Registrerade personer</h2>

      <div class="d-flex justify-content-end">
        <button @click="goBack" class="btn btn-primary">Tillbaka</button>
      </div>
    </div>

    <div class="col-lg-12 col-xl-12 order-lg-1 order-xl-1">
      <div class="widget">

        <div class="widget-header">
          <div class="widget-header-label">
            <h3>Personer</h3>
          </div>
        </div>

        <div class="widget-body" style="color: initial;">
          <div class="row">

            <div v-if="!persons.length" class="col-12 br-1 my-4 d-flex justify-content-center">
              Hittade inga personer
            </div>

            <div class="col-12 col-md-4 br-1 person" v-for="p,i in persons" :key="p.id">
              <div class="d-flex justify-content-between align-items-center bb-1 pb-2 mb-3">
                <h5 style="font-size: 1rem;">Person #{{ i + 1 }}</h5>
                <h5 style="font-size: 1rem;">H: {{ testimony.users.find(u => u.id == p.user_id).name }}</h5>
                 <v-guard :permissions="['testimony-concent-view']"> 
                  <button
                        @click="showConsentData(p.id)"
                        class="btn btn-outline-success float-right btn-sm"
                        v-if="testimony.is_editable "
                      >
                      <i class="fas fa-circle-info"></i>
                    </button>
                </v-guard>
                <button
                    @click="removePerson(p.id)"
                    class="btn btn-outline-danger float-right btn-sm ms-2"
                    v-if="testimony.is_editable"
                  >
                  <i class="fas fa-trash"></i>
                </button>
              </div>

              <select-field
                class="mb-3"
                :options="gender"
                label=""
                api="updateTestimonyPersonField"
                placeholder="Välj kön"
                :value.sync="p.gender_id"
                :model="p"
                db_field="gender_id"
                :editable="testimony.is_editable"
                :fullWidth="true"
              ></select-field>

              <select-field
                class="mb-3"
                :options="ages"
                label=""
                api="updateTestimonyPersonField"
                placeholder="Välj Åldersgrupp"
                :value.sync="p.ages_id"
                :model="p"
                db_field="ages_id"
                :editable="testimony.is_editable"
                :fullWidth="true"
              ></select-field>

              <select-field
                class="mb-3"
                :options="filterTestimonyApplicant"
                label=""
                api="updateTestimonyPersonField"
                placeholder="Välj stödsökanden"
                :value.sync="p.testimony_applicant_id"
                :model="p"
                db_field="testimony_applicant_id"
                :editable="testimony.is_editable"
                :fullWidth="true"
              ></select-field>

              <div class="testimony-options-loading" v-if="isLoadingOptions">
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
              </div>

              <checkbox-multiple-field
                api="updateTestimonyPersonField"
                :multiple="true"
                :options="testimonySupport"
                :model="p"
                label=""
                :value.sync="p.testimony_support_ids"
                db_field="testimony_support"
                :fullWidth="true"
                :editable="testimony.is_editable"
              ></checkbox-multiple-field>

              <v-guard :permissions="['testimony-support2-view']">
                <div class="pt-3 border-top">
                  <h5 style="font-size: 1rem;">INSATS</h5>
                </div>
                <checkbox-multiple-field
                  api="updateTestimonyPersonField"
                  :multiple="true"
                  :options="testimonySupport2"
                  :model="p"
                  label=""
                  :value.sync="p.testimony_support2_ids"
                  db_field="testimony_support2"
                  :fullWidth="true"
                  :editable="testimony.is_editable"
                ></checkbox-multiple-field>
              </v-guard>

              <v-guard v-if="showConsentSaveData[p.id] || p.consent_save_data" :permissions="['testimony-concent-view']">
                <div class="border-top pt-2 pb-2 mb-3">
                  <checkbox-field
                    v-if="!isLoadingOptions"
                    api="updateTestimonyPersonField"
                    :value="p.consent_save_data"
                    field="consent_save_data"
                    :model="p"
                    label="Samtycke spara personuppgifter"
                    @fieldSaved="refreshTestimony()"
                    :editable="testimony.is_editable"
                    :fullWidth="true"
                    infoBox="Samtycke - spara personuppgifter D"
                  ></checkbox-field>
              
                <div v-if="p.consent_save_data && p.consent_save_data_user">
                  <div class="text-muted pb-3 mt-1"><label>{{ moment(p.consent_save_data_timestamp).format('YYYY-MM-DD') }}</label> <label>{{ p.consent_save_data_user.name }}</label></div>


                <text-field
                  :fullWidth="true"
                  class="mb-3 text-black"
                  api="updateTestimonyPersonField"
                  :model="p"
                  :value.sync="p.first_name"
                  field="first_name"
                  db_field="first_name"
                  :label="$t('labels.first-name')"
                  type="text"
                ></text-field>
                <text-field
                    :fullWidth="true"
                    class="mb-3 text-black"
                    api="updateTestimonyPersonField"
                    :model="p"
                    :value.sync="p.last_name"
                    field="last_name"
                    db_field="last_name"
                    :label="$t('labels.last-name')"
                    type="text"
                  ></text-field>
                  <text-field
                      :fullWidth="true"
                      class="mb-3 text-black"
                      api="updateTestimonyPersonField"
                      :model="p"
                      :value.sync="p.email"
                      field="email"
                      db_field="email"
                      :label="$t('labels.email')"
                      type="text"
                    ></text-field>
                  <text-field
                        :fullWidth="true"
                        class="mb-3 text-black"
                        api="updateTestimonyPersonField"
                        :model="p"
                        :value.sync="p.mobile"
                        field="mobile"
                        db_field="mobile"
                        :label="$t('labels.mobile')"
                        type="text"
                    ></text-field>
            
                </div>
                  <div class="mt-5">
                  </div> 
                </div>
              </v-guard>
            </div>

            <!-- <div class="col-4 text-center flex-column d-flex align-content-center justify-content-center flex-wrap pt-5 pb-5">
              <div>
                <button v-if="!isAddingPerson" class="btn btn-success btn-sm" @click="addPerson">
                  <i class="fas fa-plus mr-1"></i> Lägg till person
                </button>
                <button v-if="isAddingPerson" class="btn btn-success btn-sm">
                  <i class="fas fa-circle-notch fa-spin"></i>
                </button>
              </div>
            </div> -->

          </div>
        </div>

      </div>
    </div>

  </div>

</template>

<script>
import BojAPI from '@/api/boj'
import SelectField from '@/components/fields/SelectField.vue';
import CheckboxField from '@/components/fields/CheckboxField.vue';
import CheckboxMultipleField from '@/components/fields/CheckboxMultipleField.vue';
import TextField from '@/components/fields/TextField.vue';
import moment from 'moment';

export default {
  props: {
    initialTestimony: Object,
    refreshTestimony: Function
  },
  components: {
    SelectField,
    CheckboxMultipleField,
    CheckboxField,
    TextField,
  },
  data() {
    return {
      moment: moment,
      persons: [],
      testimonyApplicant: [],
      testimonySupport: [],
      testimonySupport2: [],
      ages: [],
      gender: [],
      isAddingPerson: false,
      isLoadingOptions: false,
      testimony: {},
      showConsentSaveData: {},
    }
  },
  watch: {
    initialTestimony(newVal, oldVal) {
      this.testimony = newVal;
      this.setData(newVal.persons)
    },
  },
  mounted() {
    if (this.initialTestimony.persons !== undefined) {
      this.setData(this.initialTestimony.persons)
      this.testimony = this.initialTestimony;
    }

    this.isLoadingOptions = true;
    this.setStoreData();
  },
  computed: {
    filterTestimonyApplicant() {

      if (this.testimony.testimony_type_id == 1) {
        return this.testimonyApplicant.filter(item => item.only_civil_cases == true)
      } else {
        return this.testimonyApplicant.filter(item => item.only_civil_cases == false)
      }

    },
  },
  methods: {
    showConsentData(i) {
      this.showConsentSaveData[i] = !this.showConsentSaveData[i];
      this.refreshTestimony();
 
    },
    removePerson(person_id) {
      this.$confirm({
        message: `Vill du radera personen?`,
        button: {
          no: 'Nej',
          yes: 'Ja',
        },
        callback: (confirm) => {
          if (confirm) {
            BojAPI.removeTestimonyPerson(person_id)
              .then(() => {
                this.refreshTestimony()
                this.$toast.success('Person borttagen', {
                  position: 'top-center',
                });

                this.reRender = true;
              })
              .catch((error) => {
                this.refreshTestimony();
                this.$toast.error('Något gick fel', {
                  position: 'top-center',
                });
              });
          }
        },
      });
    },
    goBack() {
      this.$router.go(-1)
    },
    setData(payload) {
      this.persons = payload;
    },
    setStoreData() {
      this.testimonyApplicant = this.$store.getters.options.testimony_applicants;
      this.testimonySupport   = this.$store.getters.options.testimony_support;
      this.testimonySupport2   = this.$store.getters.options.testimony_support2;
      this.ages               = this.$store.getters.options.ages;
      this.gender             = this.$store.getters.options.gender;

      this.isLoadingOptions = false;
    }
  }
}
</script>

<style>
.vuetable th.sortable:hover {
  color: initial;
}

</style>
