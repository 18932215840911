<template>
<div>

    <transition name="fade" mode="out-in">

        <!-- <div class="widget-body bt-1 pr-0 pl-3" v-if="step === 1" key="testimonyCaseNumber">
          <h3>Ange eget målnummer (frivilligt)</h3>

          <p>Minerva sätter alltid ett automatiskt målnummer. Om du inte vill lägga till ett eget, klicka bara på Nästa.</p>

          <number-field
            api="updateTestimonyField"
            :editable="true"
            :model="testimony"
            :value.sync="testimony.case_number"
            field="case_number"
            db_field="case_number"
            placeholder="Målnummer"
            type="number"
            label=""
          ></number-field>

           <prev-next
            :step="step"
            @next="nextStep"
            @prev="prevStep"
          ></prev-next>

        </div> -->

        <div class="widget-body bt-1 pr-0 pl-3" v-if="step === 1" key="customCaseDate">
          <h3>Ange datum för målet</h3>

          <date-range-picker
            opens="right"
            v-model="caseDate"
            :auto-apply="false"
            date-util="moment"
            :time-picker="true"
            :locale-data="locale"
            :ranges="ranges"
            :show-dropdowns="true"
            :single-date-picker="true"
            ref="picker"
            style="color: black;"
            @update="setDate"
          >

            <template #input="picker">
              <div class="form-control text-black bg-white" disabled readonly type="text" placeholder="Datum">
                {{ caseDate.startDate | date }}
                <div v-if="!caseDate.startDate">-</div>
              </div>

              <!-- <div>Clear</div> -->
            </template>
          </date-range-picker>

          <prev-next
            :step="step"
            @next="nextStep"
            @prev="prevStep"
            :disable-next="!caseDate.startDate"
          ></prev-next>

        </div>

        <div class="widget-body bt-1 pr-0 pl-3" v-else-if="step === 2" key="testimonyTypeId">
          <h3>Typ av mål</h3>

          <p>Oftast ger vi stöd i samband med brottmål. Om vi givit info i samband med civilmål, byt till Civilmål i rutan nedan.</p>

          <select-field
            :options="testimonyTypes"
            label=""
            api="updateTestimonyField"
            placeholder="Välj typ av mål"
            :value.sync="testimony.testimony_type_id"
            :model="testimony"
            db_field="testimony_type_id"
            :editable="true"
            :fullWidth="true"
            @fieldSaved="refreshTestimony"
          ></select-field>

          <prev-next
            :step="step"
            @next="nextStep"
            @prev="prevStep"
            :disable-next="!testimony.testimony_type_id"
          ></prev-next>

        </div>

        <div class="widget-body bt-1 pr-0 pl-3" v-else-if="step === 3 && isMatchingType('Brottmål')" key="crimeId">
          <h3>Målet gäller huvudsakligen följande brottstyp</h3>
           <select-field
            :options="crimes"
            label=""
            api="updateTestimonyField"
            placeholder="Välj brottstyp"
            :value.sync="testimony.crime_id"
            :model="testimony"
            db_field="crime_id"
            :editable="true"
            :searchable="true"
            :fullWidth="false"
            :infoBox="'Domstol - brottstyp'"
          ></select-field>

          <prev-next
            :step="step"
            @next="nextStep"
            @prev="prevStep"
            :disable-next="!testimony.crime_id"
          ></prev-next>

        </div>

        <div class="widget-body bt-1 pr-0 pl-3" v-else-if="step === 3 && isMatchingType('Civilmål')" key="testimonyOtherTypeId">
          <h3>Målet gäller huvudsakligen följande typ av civilmål</h3>
          <select-field
            :options="testimonyOtherTypes"
            label=""
            api="updateTestimonyField"
            placeholder="Välj Civilmål"
            :value.sync="testimony.testimony_other_type_id"
            :model="testimony"
            db_field="testimony_other_type_id"
            :editable="true"
            :fullWidth="true"
          ></select-field>

          <prev-next
            :step="step"
            @next="nextStep"
            @prev="prevStep"
            :disable-next="!testimony.testimony_other_type_id"
          ></prev-next>

        </div>

        <div class="widget-body bt-1 pr-0 pl-3" v-else-if="step === 4" key="crimeRelationId">
          <h3>Särskilda omständigheter</h3>

          <p>Om målet gäller särskilda omständigheter, välj den viktigaste i rutan nedan. Annars: gå vidare.</p>

          <select-field
            :options="crimeRelations"
            label=""
            api="updateTestimonyField"
            placeholder="Välj särskilda omständigheter"
            :value.sync="testimony.crime_relation_id"
            :model="testimony"
            db_field="crime_relation_id"
            :editable="true"
            :fullWidth="false"
            :infoBox="'Ärendeformuläret - flik Om ärendet, Särskilda omständigheter'"
          ></select-field>

          <prev-next
            :step="step"
            @next="nextStep"
            @prev="prevStep"
          ></prev-next>

        </div>

        <!-- <div class="widget-body bt-1 pr-0 pl-3" v-else-if="step === 5" key="specialEventsId">
          <h3>Särskild händelse</h3>

          <p>Om målet gäller särskild händelse, välj den i rutan nedan. Annars: gå vidare.</p>

          <select-field
            :options="specialEvents"
            label=""
            api="updateTestimonyField"
            placeholder="Välj särskild händelse"
            :value.sync="testimony.special_events_id"
            :model="testimony"
            db_field="special_events_id"
            :editable="true"
            :fullWidth="false"
            :infoBox="'Ärendeformuläret - flik Om ärendet, Särskilda händelser'"
          ></select-field>

          <prev-next
            :step="step"
            @next="nextStep"
            @prev="prevStep"
          ></prev-next>

        </div> -->

        <div class="widget-body bt-1 pr-0 pl-3" v-else-if="step === 5" key="externalCaseId">
          <h3>Externt mål?</h3>

          <p>Du kan välja att registrera inhyrt mål eller mål på länk genom att mata in något av de alternativen nedan. Annars: tryck nästa.</p>

          <select-field
            :options="externalCases"
            label=""
            api="updateTestimonyField"
            placeholder="Välj externt mål"
            :value.sync="testimony.external_case_id"
            :model="testimony"
            db_field="external_case_id"
            :editable="true"
            :fullWidth="false"
            :infoBox="'Domstol - Externt mål'"
          ></select-field>

          <prev-next
            :step="step"
            @next="nextStep"
            @prev="prevStep"
          ></prev-next>

        </div>

        <div class="widget-body bt-1 pr-0 pl-3" v-else-if="step === 6" key="testimonySecurityRoomId">
          <h3>Säkerhetssal?</h3>

          <p>Om målet sker i säkerhetssal kan du byta till det alternativet nedan. Annars: tryck nästa.</p>

          <select-field
            :options="securityRooms"
            label=""
            api="updateTestimonyField"
            placeholder="Välj säkerhetssal"
            :value.sync="testimony.testimony_security_room_id"
            :model="testimony"
            db_field="testimony_security_room_id"
            :editable="true"
            :fullWidth="false"
            :infoBox="'Domstol - säkerhetssal'"
          ></select-field>

          <prev-next
            :step="step"
            @next="nextStep"
            @prev="prevStep"
          ></prev-next>

        </div>

        <div class="widget-body bt-1 pr-0 pl-3" v-else-if="step === 7" key="addPeople">
          <h3>Lägg till stödsökande på detta mål?</h3>
          <div class="d-flex justify-content-between mb-4">
            <button class="btn btn-primary" @click="formFinish">Nej</button>
            <button class="btn btn-primary" @click="goToAddPerson">Ja</button>
          </div>
        </div>

    </transition>

</div>

</template>

<script>
import BojAPI from '@/api/boj';
import SelectField from '@/components/fields/SelectField.vue';
import CheckboxField from '@/components/fields/CheckboxField.vue';
import NumberField from '@/components/fields/NumberField.vue';
import PrevNext from '@/components/forms/PrevNext.vue';
import InfoBox from '@/components/fields/InfoBox';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import moment from 'moment';

export default {
  components:{
    SelectField,
    CheckboxField,
    NumberField,
    DateRangePicker,
    PrevNext,
    InfoBox
  },
  props: {
    initialTestimony: Object,
    answerAddPerson: Boolean,
    refreshTestimony: Function
  },
  data() {
    return {
      step: 1,
      formEnd: false,
      testimonyTypes: [],
      testimonyOtherTypes: [],
      crimes: [],
      crimeRelations: [],
      specialEvents: [],
      externalCases: [],
      securityRooms: [],
      testimony: this.initialTestimony,
      caseDate: {
        startDate: this.initialTestimony.case_date_original,
        endDate: this.initialTestimony.case_date_original,
      },
      locale: {
        direction: 'ltr',
        format: 'mm-dd-yyyy',
        separator: ' - ',
        applyLabel: 'Välj',
        cancelLabel: 'Avbryt',
        fromLabel: 'Från',
        weekLabel: 'W',
        customRangeLabel: 'Custom Range',
        daysOfWeek: ['Sön','Mån', 'Tis', 'Ons', 'Tors', 'Fre', 'Lör'],
        monthNames: ['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December'],
        firstDay: 1,
        todayLabel:'Idag'
      },
    }
  },
  mounted() {
    this.setStoreData()
  },
  filters: {
      date(val) {
      return val ? moment(val, "YYYY-MM-DD HH:mm").format("YYYY-MM-DD HH:mm") : "";
    }
  },
  watch: {
    initialTestimony: {
      handler(data) {
        this.testimony = data
        this.caseDate.startDate = data.case_date ?? null
      },
      deep:true
    }
  },
  computed: {
    ranges() {

        return {
          Idag: [moment().toDate(), moment().toDate()],
          Imorgon: [
              moment().add(1, "days").toDate(),
              moment().add(1, "days").toDate()
          ],
          Igår: [
              moment().subtract(1, "days").toDate(),
              moment().subtract(1, "days").toDate()
          ]
        }
    },
  },
  methods: {
    refreshBeforeNextStep() {
      this.refreshTestimony()
        .then(() => {
          if (!this.testimony.court_contact_types.add_testimony) {
            this.$emit('setCurrentStep', 1);
          }

          this.step++;
        })
        .catch((e) => console.log('ERROR: refreshBeforeNextStep', e))
    },
    setDate(value) {
      const post = {
        field: 'case_date',
        ids: moment(value.startDate, "YYYY-MM-DD HH:mm").format("YYYY-MM-DD HH:mm")
      }

      BojAPI['updateTestimonyField'](this.testimony, post)
      .then((response) => {
        // this.refreshBeforeNextStep()

        this.$toast.success('Ändringar sparade', {
          position: 'top-center',
        });
      })
      .catch((error) => {
        this.$toast.error('Något gick fel', {
          position: 'top-center',
        });
      });
    },
    nextStep() {

      if (this.step == 3 && this.isMatchingType('Civilmål')) {
        this.step = 7;
        return;
      }

      this.step++;
    },
    prevStep() {
      if (this.step != 1) {
        this.step--;
      }
    },
    isMatchingType(type) {
      let selectedType = this.testimonyTypes.find(type => type.id == this.testimony.testimony_type_id);

      if (selectedType && selectedType.label == type) {
        return true;
      }

      return false
    },
    goToAddPerson() {
      this.$emit('setCurrentStep', 1);
    },
    formFinish() {
      this.$emit('finish');
    },
    setStoreData() {
      this.testimonyTypes = this.$store.getters.options.testimony_types;
      this.testimonyOtherTypes = this.$store.getters.options.testimony_other_types;
      this.crimes = this.$store.getters.options.crime;
      this.crimeRelations = this.$store.getters.options.crime_relation;
      this.specialEvents = this.$store.getters.options.special_event;
      this.externalCases = this.$store.getters.options.external_cases;
      this.securityRooms = this.$store.getters.options.testimony_security_room;
    }
  }
}
</script>

<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s ease;
}
.fade-enter, .fade-leave-to
/* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
