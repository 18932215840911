<template>
  <div class="form-group row">

    <div :class="label.length !== 0 && !fullWidth ? 'col-sm-8' : 'col-12'">
      <label :for="db_field + '_field'" :class="{ 'col-sm-4': !fullWidth, 'col-sm-12': fullWidth }" class="col-form-label mb-3"
           v-if="label.length !== 0">{{
        label
      }}</label>

      <div class="form-check my-2" v-for="option in options" :key="option.id">
          <!-- <pre>{{model}}</pre> -->
          <input
            class="form-check-input"
            type="checkbox"
            :id="model.id +'-'+ option.id"
            :value="option.id"
            v-model="localValue"
            @change="updateField"
            :disabled="!editable"
          >
          <label class="form-check-label" :for="model.id +'-'+ option.id">{{option.label}}</label>

      </div>

    </div>
  </div>
</template>

<script>
import BojAPI from '@/api/boj';
import _ from 'lodash'

export default {
  name: 'loader',
  props: {
    fullWidth: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    optionLabel: {
      type: String,
      default: 'label',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    useStore: {
      type: Boolean,
      default: false
    },
    dispatch: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    api: String,
    options: Array,
    model: Object,
    value: Array,
    db_field: String,
    label: String,
  },
  watch: {
    value: {
      handler () {
        this.localValue = this.value
      },
      deep: false
    },
  },
  data() {
    return {
      localValue: this.value
    }
  },
  computed: {
    isModelFieldEmpty() {
      if (_.isObject(this.model[this.field])) {
        if (_.isArray(this.model[this.field]) && !this.model[this.field].length) {
          return true;
        }
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
    updateField(event) {

      /**
       * TODO: Set dispatch to work dynamically
       */

      if (this.useStore) {
        this.$store.dispatch(this.dispatch, event)
        .then(() => {
          console.log('dispatch '+ this.dispatch +' is set');
          this.$emit('update:value', this.localValue);
        })

        return;
      }

      this.$emit('update:value', this.localValue);

      const post = {
        field: this.db_field,
        ids: this.localValue
      }

      BojAPI[this.api](this.model, post)
        .then(() => {
          // this.$emit('fieldSaved');
          // this.$emit('fieldSavedWithData', {key: field, value: this.localValue});
          this.$toast.clear();

          this.$toast.success('Ändringar sparade', {
            position: 'top-center',
          });

        })
        .catch(() => {
          this.$toast.error('Något gick fel', {
            position: 'top-center',
          });
        });
    },
  },
};
</script>
