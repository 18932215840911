<template>
  <div class="form-group row">
    <label :for="'input_' + db_field" class="col-10 col-form-label" v-if="label.length !== 0">
      {{ label }}

    </label>
    <div class="col-2 d-flex justify-content-end align-items-center" v-if="removable">
      <button
          @click="deleteEntry()"
          class="btn btn-outline-danger btn-sm bg-white ms-auto"
        >
          <i class="fas fa-trash bg-white"></i>
      </button>
    </div>
    <div v-if="rows === 1" class="position-relative"
         :class="inputClasses">
      <input
        autocomplete="chrome-off"
        list="autocompleteOff"
        v-if="editable"
        :type="type"
        :value="localValue"
        v-on:keyup.enter="!submitWithButton ? updateField($event) : () => {}"
        :placeholder="placeholder"
        @blur="!submitWithButton ? updateField($event) : () => {}"
        class="form-control bg-white d-inline-block"
        :id="'input_' + db_field"
        pattern="\d*"
        @input="limitCharacters"
      />

      <div v-if="submitWithButton" class="d-flex justify-content-end">
        <button @click="confirmSave" class="btn btn-primary mt-4" :class="{'disabled': !localValue}">Registrera tid</button>
      </div>

      <small class="text-muted mt-1" v-if="help.length !== 0">{{ help }}</small>
      <slot></slot>
      <div class="pt-2" v-if="!editable"
           v-html="localValue !== '' ? localValue : '<span class=\'text-muted\'>-</span>'"></div>
    </div>

    <div class="col-sm-2" v-if="type == 'email'">
      <a tabindex="-1" :href="'mailto:' + localValue" class="h-100 btn w-100 btn-light"><i
          class="mt-2 fas fa-envelope opacity-40"></i></a>
    </div>
    <div class="col-sm-2" v-if="type == 'tel'">
      <a tabindex="-1" :href="'tel:' + localValue" class="h-100 btn w-100 btn-light"><i
          class="mt-2 fas fa-phone opacity-40"></i></a>
    </div>
  </div>
</template>

<script>
import BojAPI from '@/api/boj';
import Multiselect from '@vueform/multiselect/dist/multiselect.vue2.js'
import _ from 'lodash'

export default {
  name: 'loader',
  components: {
    Multiselect
  },
  props: {
    fullWidth: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    optionLabel: {
      type: String,
      default: 'label',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    deselect: {
      type: Boolean,
      default: false,
    },
    useStore: {
      type: Boolean,
      default: false
    },
    dispatch: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text',
    },
    rows: {
      type: Number,
      default: 1,
    },
    help: {
      type: String,
      default: '',
    },
    timeType: {
      type: Object,
      default: () => {},
    },
    submitWithButton: {
      type: Boolean,
      default: false
    },
    removable: {
      type: Boolean,
      default: false
    },
    api: String,
    options: Array,
    model: Object,
    value: Number,
    db_field: String,
    label: String,
  },
  watch: {
    value: {
      handler () {
        this.localValue = this.value;
      },
      deep: false
    },
  },
  data() {
    return {
      localValue: this.value,
      maxLength: 9
    }
  },
  computed: {
    isModelFieldEmpty() {
      if (_.isObject(this.model[this.field])) {
        if (_.isArray(this.model[this.field]) && !this.model[this.field].length) {
          return true;
        }
        return false;
      } else {
        return true;
      }
    },
    inputClasses() {
      return {
        'col-sm-12': this.type !== 'email' && this.type !== 'tel',
        'col-sm-6 pr-0': this.type != 'email' && this.type == 'tel',
        'mt-3': this.removable,
      }
    },
  },
  methods: {
    limitCharacters(event) {
      const value = event.target.value
      if (String(value).length <= this.maxLength) {
        this.localValue = parseInt(value)
      }
      this.$forceUpdate()
    },
    deleteEntry() {
      this.$confirm({
        message: `Vill du radera tiden?`,
        button: {
          no: 'Nej',
          yes: 'Ja',
        },
        callback: (confirm) => {
          if (confirm) {

             const post = [
              this.model.id,
              this.timeType.id
            ]

            BojAPI.removeTestimonyTime(...post)
              .then(() => {
                this.$toast.success('Tiden raderad', {
                  position: 'top-center',
                });

                this.$emit('goBack');
              })
              .catch((error) => {
                if (error.response.status == 404) {
                  this.$toast.error('Inmatade tiden finns inte', {
                    position: 'top-center',
                  });
                } else {
                  this.$toast.error('Något gick fel', {
                    position: 'top-center',
                  });
                 }
              });
          }
        },
      });
    },
    confirmSave() {
      this.$confirm(
        {
          message: 'Var god kontrollera inmatningen. Tryck därefter OK för att registrera tiden.',
          button: {
            no: 'Tillbaka',
            yes: 'OK'
          },
          /**
          * Callback Function
          * @param {Boolean} confirm
          */
          callback: async confirm => {
            if (confirm) {
              await this.updateField().then(() => {
                this.$emit('goBack');
              })
            }
          }
        }
      )
    },
    async updateField() {
      let value = this.localValue

      /**
       * TODO: Set dispatch to work dynamically
       */

      if (this.useStore) {
        this.$store.dispatch(this.dispatch, value)
        .then(() => {
          this.$emit('update:value', value);
        })

        return;
      }

      this.$emit('update:value', value);

      const post = {
        type: this.timeType,
        field: this.db_field,
        ids: value
      }

      return BojAPI[this.api](this.model, post)
        .then((response) => {
          this.$emit('fieldSaved');
          this.$toast.clear();

          this.$toast.success('Ändringar sparade', {
            position: 'top-center',
          });

          return response
        })
        .catch((err) => {
          this.$toast.error('Något gick fel', {
            position: 'top-center',
          });

          return err
        });
    },
  },
};
</script>
