<template>

  <div>
    <transition name="fade" mode="out-in">

      <div class="widget-body bt-1 pr-0 pl-3" v-if="step === 1" key="testimonyApplicantId">
        <h3>Typ av stödsökande?</h3>

        <p>Vi har oftast kontakt med vittnen, men du kan byta till andra typer av stödsökande nedan. Tryck därefter på Nästa.</p>

        <select-field
          :options="filterTestimonyApplicant"
          label=""
          api="updateTestimonyPersonField"
          placeholder="Välj stödsökanden"
          :value.sync="person.testimony_applicant_id"
          :model="person"
          db_field="testimony_applicant_id"
          :editable="true"
          :fullWidth="true"
        ></select-field>

        <prev-next
          :step="step"
          @next="nextStep"
          @prev="prevStep"
        ></prev-next>

      </div>

      <div class="widget-body bt-1 pr-0 pl-3" v-else-if="step === 2" key="genderId">
        <h3>Kön?</h3>

        <p>Vi har oftast kontakt med kvinnor, men du kan byta till andra typer av kön nedan. Tryck därefter på Nästa.</p>

        <select-field
          :options="gender"
          label=""
          api="updateTestimonyPersonField"
          placeholder="Välj kön"
          :value.sync="person.gender_id"
          :model="person"
          db_field="gender_id"
          :editable="true"
          :fullWidth="false"
          :infoBox="'Ärendeformuläret - flik Om stödsökanden, Kön'"
        ></select-field>

        <prev-next
          :step="step"
          @next="nextStep"
          @prev="prevStep"
        ></prev-next>

      </div>

      <div class="widget-body bt-1 pr-0 pl-3" v-else-if="step === 3" key="agesId">
        <h3>Åldersgrupp?</h3>

        <p>Vi har oftast kontakt med personer i gruppen 26-64, men du kan byta till andra åldersgrupper nedan. Tryck därefter på Nästa.</p>

        <select-field
          :options="ages"
          label=""
          api="updateTestimonyPersonField"
          placeholder="Välj Åldersgrupp"
          :value.sync="person.ages_id"
          :model="person"
          db_field="ages_id"
          :editable="true"
          :fullWidth="false"
          :infoBox="'Ärendeformuläret - flik Om stödsökanden, Åldersgrupp'"
        ></select-field>

        <prev-next
          :step="step"
          @next="nextStep"
          @prev="prevStep"
        ></prev-next>

      </div>

      <div class="widget-body bt-1 pr-0 pl-3" v-else-if="step === 4" key="testimonySupport">
        <h3>Stöd när?</h3>

        <checkbox-multiple-field
          api="updateTestimonyPersonField"
          :multiple="true"
          :options="testimonySupport"
          :model="person"
          label=""
          :value.sync="person.testimony_support"
          db_field="testimony_support"
          :fullWidth="true"
        ></checkbox-multiple-field>

        <div class="d-flex justify-content-between w-100 mt-4">
          <button class="btn btn-primary" @click="prevStep">Tillbaka</button>
          <button class="btn btn-primary" @click="nextStep">Nästa</button>
        </div>

      </div>

      <div class="widget-body bt-1 pr-0 pl-3" v-else-if="step === 5 && this.$store.getters.permissions.includes('testimony-support2-view')" key="testimonySupport2">
        <h3>Insats</h3>

        <checkbox-multiple-field
          api="updateTestimonyPersonField"
          :multiple="true"
          :options="testimonySupport2"
          :model="person"
          label=""
          :value.sync="person.testimony_support2"
          db_field="testimony_support2"
          :fullWidth="true"
        ></checkbox-multiple-field>

        <div class="d-flex justify-content-between w-100 mt-4">
          <button class="btn btn-primary" @click="prevStep">Tillbaka</button>
          <button class="btn btn-primary" @click="nextStep">Nästa</button>
        </div>

      </div>

      <div class="widget-body bt-1 pr-0 pl-3" v-else-if="step === 5 || step === 6" key="addPeopleAgain">
        <h3>Lägg till ny stödsökande på detta mål?</h3>
        <div class="d-flex justify-content-between w-100 mt-4">
          <button class="btn btn-primary" @click="formFinish">Nej</button>
          <button class="btn btn-primary" @click="AddPersonAgain">Ja</button>
        </div>

      </div>
    </transition>

  </div>

</template>

<script>
import SelectField from '@/components/fields/SelectField.vue';
import CheckboxField from '@/components/fields/CheckboxField.vue';
import CheckboxMultipleField from '@/components/fields/CheckboxMultipleField.vue';
import PrevNext from '@/components/forms/PrevNext.vue';

export default {
  components:{
    SelectField,
    CheckboxField,
    CheckboxMultipleField,
    PrevNext
  },
  props: {
    initialPerson: Object,
    initialTestimony: Object,
    answerAddPerson: Boolean
  },
  data() {
    return {
      step: 1,
      testimonyApplicant: [],
      testimonySupport: [],
      testimonySupport2: [],
      testimonyTypes: [],
      ages: [],
      gender: [],
      person: {},
      testimony: {},
    }
  },
  mounted() {
    this.setStoreData()
    if (Object.keys(this.initialPerson).length) {
      this.person = this.initialPerson;
    }
    if (Object.keys(this.initialTestimony).length) {
      this.testimony = this.initialTestimony;
    }
  },
  watch: {
    initialPerson: {
      handler(data) {
        this.person = data
      }
    },
    initialTestimony: {
      handler(data) {
        this.testimony = data
      }
    }
  },
  computed: {
    filterTestimonyApplicant() {

      if (this.isMatchingType('Civilmål')) {
        return this.testimonyApplicant.filter(item => item.only_civil_cases == true)
      } else {
        return this.testimonyApplicant.filter(item => item.only_civil_cases == false)
      }

    },
  },
  methods: {
    AddPersonAgain() {
      this.$emit('setCurrentStep', 1);
      this.step = 1
    },
    nextStep() {
      this.step++;
    },
    prevStep() {
      if (this.step != 1) {
        this.step--;
      } else {
        this.$emit('setCurrentStep', 0);
      }
    },
    formFinish() {
      this.$emit('finish');
    },
    isMatchingType(type) {
      let selectedType = this.testimonyTypes.find(type => type.id == this.testimony.testimony_type_id);

      if (selectedType && selectedType.label == type) {
        return true;
      }

      return false
    },
    setStoreData() {
      this.testimonyTypes = this.$store.getters.options.testimony_types;
      this.testimonyApplicant = this.$store.getters.options.testimony_applicants;
      this.testimonySupport   = this.$store.getters.options.testimony_support;
      this.testimonySupport2   = this.$store.getters.options.testimony_support2;
      this.ages               = this.$store.getters.options.ages;
      this.gender             = this.$store.getters.options.gender;
    }
  }
}
</script>

<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s ease;
}
.fade-enter, .fade-leave-to
/* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
