<template>
  <div class="d-flex justify-content-between w-100 mt-4">
      <button class="btn btn-primary" v-if="step != 1" :class="{'disabled':disablePrev}" @click="prevStep">Tillbaka</button>
      <button class="btn btn-primary ms-auto" :class="{'disabled':disableNext}" @click="nextStep">Nästa</button>
    </div>
</template>

<script>

export default {
  props: {
    step: Number,
    "disable-prev": {
      type: Boolean,
      default: false
    },
    "disable-next": {
      type: Boolean,
      default: false
    }
  },
  methods: {
    nextStep() {
      this.$emit('next');
    },
    prevStep() {
      if (this.step != 1) {
        this.$emit('prev');
      }
    },
  }
}
</script>
