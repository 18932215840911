<template>
  <div class="form-group row justify-content-between mb-3">
    <label :for="'input_' + db_field" class="col-2 col-form-label" v-if="label.length !== 0">
      {{ label }}
    </label>
    <div v-if="rows === 1" class="position-relative"
         :class="type !== 'email' && type !== 'tel' ? 'col-sm-8' : 'col-sm-6 pr-0'">
      <input
        autocomplete="chrome-off"
        list="autocompleteOff"
        v-if="editable"
        :type="type"
        v-model.number="localValue"
        v-on:keyup.enter="$event.target.blur()"
        :placeholder="placeholder"
        @blur="updateField"
        class="form-control bg-white"
        :id="'input_' + db_field"
        pattern="[0-9]+"
      />

      <small class="text-muted mt-1" v-if="help.length !== 0">{{ help }}</small>
      <slot></slot>
      <div class="pt-2" v-if="!editable"
           v-html="localValue !== '' ? localValue : '<span class=\'text-muted\'>-</span>'"></div>
    </div>

    <div class="col-sm-2" v-if="type == 'email'">
      <a tabindex="-1" :href="'mailto:' + localValue" class="h-100 btn w-100 btn-light"><i
          class="mt-2 fas fa-envelope opacity-40"></i></a>
    </div>
    <div class="col-sm-2" v-if="type == 'tel'">
      <a tabindex="-1" :href="'tel:' + localValue" class="h-100 btn w-100 btn-light"><i
          class="mt-2 fas fa-phone opacity-40"></i></a>
    </div>
  </div>
</template>

<script>
import BojAPI from '@/api/boj';
import Multiselect from '@vueform/multiselect/dist/multiselect.vue2.js'
import _ from 'lodash'

export default {
  name: 'loader',
  components: {
    Multiselect
  },
  props: {
    fullWidth: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    optionLabel: {
      type: String,
      default: 'label',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    deselect: {
      type: Boolean,
      default: false,
    },
    useStore: {
      type: Boolean,
      default: false
    },
    dispatch: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text',
    },
    rows: {
      type: Number,
      default: 1,
    },
    help: {
      type: String,
      default: '',
    },
    timeType: {
      type: Object,
      default: () => {},
    },
    api: String,
    options: Array,
    model: Object,
    value: String,
    db_field: String,
    label: String,
  },
  watch: {
    value: {
      handler () {
        this.localValue = this.value;
      },
      deep: false
    },
  },
  data() {
    return {
      localValue: this.value
    }
  },
  computed: {
    isModelFieldEmpty() {
      if (_.isObject(this.model[this.field])) {
        if (_.isArray(this.model[this.field]) && !this.model[this.field].length) {
          return true;
        }
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
    updateField(fieldData) {
      
      let value = '';

      if (fieldData === null) {
        value = null;
      } else {
        if (this.multiple) {
          value = fieldData;
        } else {
          value = fieldData.target.value;
        }
      }

      /**
       * TODO: Set dispatch to work dynamically
       */

      if (this.useStore) {
        this.$store.dispatch(this.dispatch, value)
        .then(() => {
          console.log('dispatch '+ this.dispatch +' is set');
          this.$emit('update:value', value);
        })

        return;
      }

      this.$emit('update:value', value);

      const post = {
        type: this.timeType,
        field: this.db_field,
        ids: value
      }

      BojAPI[this.api](this.model, post)
        .then(() => {
          this.$emit('fieldSaved');
          // this.$emit('fieldSavedWithData', {key: field, value: this.localValue});
          this.$toast.clear();

          this.$toast.success('Ändringar sparade', {
            position: 'top-center',
          });
        })
        .catch((error) => {
          let  text = 'Något gick fel';
          if (error.response.data.errors && error.response.data.errors.email) {
            text = 'Felaktig e-postadress';
          } else if (error.response.data.errors && error.response.data.errors.mobile) {
            text = 'Felaktigt mobilnummer';
          }
          
          this.$toast.error(text, {
            position: 'top-center',
          }); 
        });
    },
  },
};
</script>
