<template>
  <div class="form-group row">

    <div class="d-flex d-flex justify-content-between align-items-center">
      <div class="col-sm-2">
        <info-box :field="infoBox" :color="'#198754'" />
      </div>
      <div class="col-sm-8">
        <label class="col-form-label" v-if="label.length !== 0">     {{ label }}</label>
      </div>
        <div class="col-sm-2 form-check">
          <input
              value="1"
              :type="type"
              v-model="localValue"
              @change="updateField"
              :id="'input' + field"
              class="form-check-input"
          />
        </div>
      </div>
  </div>
</template>

<script>
import BojAPI from '@/api/boj';
import InfoBox from '@/components/fields/InfoBox';
import _ from 'lodash'

export default {
  name: 'loader',
  components: {
    InfoBox
  },
    props: {
    api: String,
    editable: {
      type: Boolean,
      default: true,
    },
    text: String,
    type: {
      type: String,
      default: 'checkbox',
    },

    help: {
      type: String,
      default: '',
    },
    model: Object,
    field: String,
    label: String,
    value: Number,
    disabled: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: Boolean,
      default: false
    },
    save: {
      type: Boolean,
      default: true,
    },
    useStore: {
      type: Boolean,
      default: false
    },
    dispatch: {
      type: String,
      default: ''
    },
    infoBox: String
  },
  watch: {
    value: {
      handler () {
        this.localValue = this.value == 1
      },
      deep: false
    },
  },
  data() {
    return {
      localValue: this.value == 1
    }
  },
  computed: {
  },
  methods: {
    updateField(event) {


      let value = '';

      if (event === null) {
        value = null;
      } else {
        if (this.multiple) {
          value = event.taget.value;
        } else {
          value = event.target.checked ? parseInt(event.target.value) : 0;
        }
      }

      /**
       * TODO: Set dispatch to work dynamically
       */

      if (this.useStore) {
        this.$store.dispatch(this.dispatch, event)
        .then(() => {
          this.$emit('update:value', value);
        })

        return;
      }

      this.$emit('update:value', value);

      const post = {
        field: this.field,
        ids: value
      }

      BojAPI[this.api](this.model, post)
        .then(() => {
          this.$emit('fieldSaved');
          this.$toast.clear();
          // this.$emit('fieldSavedWithData', {key: field, value: this.localValue});

          this.$toast.success('Ändringar sparade', {
            position: 'top-center',
          });
        })
        .catch(() => {
          this.$toast.error('Något gick fel', {
            position: 'top-center',
          });
        });
    },
  },
};
</script>
